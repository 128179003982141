import { Alert, AlertIcon, Box, Button, Heading, Link } from '@chakra-ui/core';
import React, { Fragment, useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from '../common/components/Loader';
import MaskedNumberField from '../common/components/MaskedNumberField';
import { required } from '../common/utils/formValidators';
import {
  completeOob,
  getCompleteOob,
  getInitiateOob,
  initiateOob,
} from '../slice';

const Oob = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading: initiateOobIsLoading, error: initiateOobError } =
    useSelector(getInitiateOob);
  const {
    isLoading: completeOobIsLoading,
    success: completeOobSuccess,
    error: completeOobError,
  } = useSelector(getCompleteOob);

  const onSubmit = useCallback(
    (values) => {
      dispatch(completeOob(values));
    },
    [dispatch]
  );

 const onInitiateOob = useCallback(() => {	
    dispatch(initiateOob());	 
  }, [dispatch]);

  useEffect(() => {
    onInitiateOob();
  }, [onInitiateOob]);

  useEffect(() => {
    if (completeOobSuccess) {
      history.push('/confirm-enrollment');
    }
  }, [completeOobSuccess, history]);

  if (initiateOobIsLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Heading fontSize="md" my={8}>
        Enter the code sent to your email
      </Heading>
      <Box width={['100%', '100%', '100%', '50%']}>
        {(initiateOobError || completeOobError) && (
          <Alert status="error" mb={8}>
            <AlertIcon />
            {initiateOobError || completeOobError}
          </Alert>
        )}
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="otp"
                component={MaskedNumberField}
                customFormat="# # # # # #"
                validate={required}
              />
              <Link color="purple.500" onClick={onInitiateOob}>
                Resend OTP
              </Link>
              <Box mt={8}>
                <Button
                  size="lg"
                  type="submit"
                  variantColor="purple"
                  isLoading={completeOobIsLoading}
                >
                  Continue
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
    </Fragment>
  );
};

export default Oob;
