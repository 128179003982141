import { createSlice } from '@reduxjs/toolkit';
import { find, get, isEmpty, some } from 'lodash-es';

import { APP_INSTANCE } from './app/constants';

function changePostCode(value) {
  if (!value.includes('-')) {
      return value.slice(0, 3) + "-" + value.slice(3);
  }
  return value;
}

const setRequestState = ({ state, key }) => {
  state[key].isLoading = true;
  state[key].success = false;
  state[key].error = null;
};

const setSuccessState = ({ state, key }) => {
  state[key].isLoading = false;
  state[key].success = true;
  state[key].error = null;
};

const setFailureState = ({ state, action, key }) => {
  state[key].isLoading = false;
  state[key].success = false;
  state[key].error = action.payload;
};

const initialState = {
  isLoading: true,
  error: null,
  card: null,
  srcProfile: null,
  isRecognized: false,
  email: null,
  srcCorrelationId: null,
  idToken: null,
  checkoutResponseUri: null,
  addConsumerIdentifiers: {
    isLoading: false,
    success: false,
    error: null,
  },
  addAddress: {
    isLoading: false,
    data: null,
    error: null,
  },
  initiateIdentityValidation: {
    isLoading: false,
    success: false,
    error: null,
  },
  completeIdentityValidation: {
    isLoading: false,
    success: false,
    error: null,
  },
  confirmEnrollment: {
    isLoading: false,
    success: false,
    error: null,
  },
  initiateOob: {
    isLoading: false,
    success: false,
    error: null,
  },
  completeOob: {
    isLoading: false,
    success: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'dcf',
  initialState,
  reducers: {
    initAppRequest(state) {
      state.isLoading = true;
      state.srcProfile = null;
      state.card = null;
      state.srcCorrelationId = null;
      state.error = null;
      state.isRecognized = false;
    },
    initAppSuccess(state, action) {
      state.isLoading = false;
      state.srcProfile = action.payload.srcProfile;
      state.card = action.payload.card;
      state.srcCorrelationId = action.payload.srcCorrelationId;
      state.isRecognized = action.payload.isRecognized;
      state.error = null;
      state.idToken = action.payload.idToken;
      state.checkoutResponseUri = action.payload.checkoutResponseUri;
    },
    initAppFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.srcProfile = null;
      state.card = null;
      state.srcCorrelationId = null;
      state.isRecognized = false;
    },
    addConsumerIdentifiersRequest(state) {
      setRequestState({ state, key: 'addConsumerIdentifiers' });
    },
    addConsumerIdentifiersSuccess(state, action) {
      setSuccessState({ state, key: 'addConsumerIdentifiers' });
      state.email = action.payload.email;
    },
    addConsumerIdentifiersFailure(state, action) {
      setFailureState({ state, action, key: 'addConsumerIdentifiers' });
    },
    addAddressRequest(state) {
      state.addAddress.isLoading = true;
      state.addAddress.data = null;
      state.addAddress.error = null;
    },
    addAddressSuccess(state, action) {
      state.addAddress.isLoading = false;
      state.addAddress.data = action.payload;
      state.addAddress.error = null;
    },
    addAddressFailure(state, action) {
      state.addAddress.isLoading = false;
      state.addAddress.data = null;
      state.addAddress.error = action.payload;
    },
    initiateIdentityValidationRequest(state) {
      setRequestState({ state, key: 'initiateIdentityValidation' });
    },
    initiateIdentityValidationSuccess(state) {
      setSuccessState({ state, key: 'initiateIdentityValidation' });
    },
    initiateIdentityValidationFailure(state, action) {
      setFailureState({ state, action, key: 'initiateIdentityValidation' });
    },
    completeIdentityValidationRequest(state) {
      setRequestState({ state, key: 'completeIdentityValidation' });
    },
    completeIdentityValidationSuccess(state, action) {
      setSuccessState({ state, key: 'completeIdentityValidation' });
      state.srcProfile = action.payload.srcProfile;
      state.card = action.payload.card.maskedCard;
      state.srcCorrelationId = action.payload.srcProfile.srcCorrelationId;
      state.idToken = action.payload.idToken;
    },
    completeIdentityValidationFailure(state, action) {
      setFailureState({ state, action, key: 'completeIdentityValidation' });
    },
    confirmEnrollmentRequest(state) {
      setRequestState({ state, key: 'confirmEnrollment' });
    },
    confirmEnrollmentSuccess(state) {
      setSuccessState({ state, key: 'confirmEnrollment' });
    },
    confirmEnrollmentFailure(state, action) {
      setFailureState({ state, action, key: 'confirmEnrollment' });
    },
    initiateOobRequest(state) {
      setRequestState({ state, key: 'initiateOob' });
    },
    initiateOobSuccess(state) {
      setSuccessState({ state, key: 'initiateOob' });
    },
    initiateOobFailure(state, action) {
      setFailureState({ state, action, key: 'initiateOob' });
    },
    completeOobRequest(state) {
      setRequestState({ state, key: 'completeOob' });
    },
    completeOobSuccess(state) {
      setSuccessState({ state, key: 'completeOob' });
    },
    completeOobFailure(state, action) {
      setFailureState({ state, action, key: 'completeOob' });
    },
  },
});

export const {
  addConsumerIdentifiersRequest,
  addConsumerIdentifiersSuccess,
  addConsumerIdentifiersFailure,
  addAddressRequest,
  addAddressSuccess,
  addAddressFailure,
  initiateIdentityValidationRequest,
  initiateIdentityValidationSuccess,
  initiateIdentityValidationFailure,
  completeIdentityValidationRequest,
  completeIdentityValidationSuccess,
  completeIdentityValidationFailure,
  confirmEnrollmentRequest,
  confirmEnrollmentSuccess,
  confirmEnrollmentFailure,
  initiateOobRequest,
  initiateOobSuccess,
  initiateOobFailure,
  completeOobRequest,
  completeOobSuccess,
  completeOobFailure,
  initAppRequest,
  initAppSuccess,
  initAppFailure,
} = slice.actions;

export default slice.reducer;

export const initApp =
  ({
    card,
    srcProfile,
    srcCorrelationId,
    isRecognized,
    checkoutResponseUri,
    idToken,
  }) =>
  async (dispatch) => {
    try {
      dispatch(initAppRequest());
      if (srcProfile && !card.maskedBillingAddress) {
        const { maskedCard, srcCorrelationId: newSrcCorrelationId } =
          await window.srcsdk.repeatEnrollCard({
            srcDigitalCardId: card.srcDigitalCardId,
            srcCorrelationId,
          });
        const defaultBillingAddr = {name: "TAKAHIRO SHIBATA", line1: "DEFAULT LINE1", city: "TOKYO", zip: "12345", countryCode: "JP"};
        const defaultAddrResp = await window.srcsdk.addBillingAddress({
        cardId: card.srcDigitalCardId,
        srcCorrelationId,
        billingAddress: defaultBillingAddr,
        route: 'dcf',
        });
      dispatch(
          initAppSuccess({
            card: maskedCard,
            srcCorrelationId: newSrcCorrelationId,
            srcProfile,
            isRecognized,
            checkoutResponseUri,
            idToken,
          })
        );
      } else {
        dispatch(
          initAppSuccess({
            card,
            srcCorrelationId,
            srcProfile,
            isRecognized,
            checkoutResponseUri,
            idToken,
          })
        );
      }
    } catch (error) {
      dispatch(
        initAppFailure(
          get(error, 'response.data.error.message', 'Something went wrong')
        )
      );
    }
  };

export const addConsumerIdentifiers =
  ({ email, requestRememberMeCookie = false }) =>
  async (dispatch, getState) => {
    try {
      dispatch(addConsumerIdentifiersRequest());
      const { srcCorrelationId } = getState();
      const appInstance = requestRememberMeCookie
        ? {
            appInstance: APP_INSTANCE,
          }
        : {};
      await window.srcsdk.addConsumerIdentifiers({
        consumer: {
          consumerIdentity: {
            identityType: 'EMAIL_ADDRESS',
            identityValue: email,
            identityProvider: 'SRC',
          },
          firstName: 'Dave',
          lastName: 'Murphy',
          fullName: email,
	  languageCode: "EN",
        },
        srcCorrelationId,
        requestRememberMeCookie,
        ...appInstance,
      });
      dispatch(addConsumerIdentifiersSuccess({ email }));
    } catch (error) {
      dispatch(
        addConsumerIdentifiersFailure(
          get(error, 'response.data.error.message', 'Something went wrong')
        )
      );
    }
  };

export const addAddress =
  ({ billingAddress, shippingAddress, setAsShippingAddress = false }) =>
  async (dispatch, getState) => {
    try {
      dispatch(addAddressRequest());
      let maskedShippingAddress = null;
      const srcDigitalCardId = get(getState(), 'card.srcDigitalCardId');
      const srcCorrelationId = get(getState(), 'srcCorrelationId');
      if (!isEmpty(billingAddress)) {
        billingAddress.zip = changePostCode(billingAddress.zip);
        const { data } = await window.srcsdk.addBillingAddress({
          cardId: srcDigitalCardId,
          srcCorrelationId,
          billingAddress,
          setAsShippingAddress,
          route: 'dcf',
        });
        maskedShippingAddress = data.maskedShippingAddress;
      }
      if (!maskedShippingAddress) {
        shippingAddress.zip = changePostCode(shippingAddress.zip);
        const { data } = await window.srcsdk.addShippingAddress({
          srcCorrelationId,
          shippingAddress,
          route: 'dcf',
        });
        maskedShippingAddress = data.maskedShippingAddress;
      }
      dispatch(addAddressSuccess(maskedShippingAddress));
    } catch (error) {
      dispatch(
        addAddressFailure(
          get(error, 'response.data.error.message', 'Something went wrong')
        )
      );
    }
  };

export const initiateIdentityValidation = () => async (dispatch) => {
  try {
    dispatch(initiateIdentityValidationRequest());
    await window.srcsdk.initiateIdentityValidation({
      requestedValidationChannelId: null,
      route: 'dcf',
    });
    dispatch(initiateIdentityValidationSuccess({}));	  
  } catch (error) {
    dispatch(
      initiateIdentityValidationFailure(
        get(error, 'response.data.message', 'Something went wrong')	      
      )
    );
  }
};

export const completeIdentityValidation =
  ({ otp }) =>
  async (dispatch, getState) => {
    try {
      dispatch(completeIdentityValidationRequest());
      const { idToken } = await window.srcsdk.completeIdentityValidation({
        validationData: otp,
        route: 'dcf',
      });
      const srcCorrelationId = get(getState(), 'srcCorrelationId');
      const srcDigitalCardId = get(getState(), 'card.srcDigitalCardId');
      const card = await window.srcsdk.repeatEnrollCard({
        srcDigitalCardId,
        srcCorrelationId,
      });
      const srcProfile = await window.srcsdk.getSrcProfile({
        idTokens: [idToken],
      });
      dispatch(
        completeIdentityValidationSuccess({ srcProfile, card, idToken })
      );
    } catch (error) {
      dispatch(
        completeIdentityValidationFailure(
          get(error, 'response.data.error.message', 'Something went wrong')
        )
      );
    }
  };

export const confirmEnrollment =
  ({ requestRememberMeCookie = false, shouldFail = false } = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch(confirmEnrollmentRequest());
      const srciTransactionId = get(getState(), 'srciTransactionId');
      const srcCorrelationId = get(getState(), 'srcCorrelationId');
      const srcDigitalCardId = get(getState(), 'card.srcDigitalCardId');
      const shippingAddressId = get(getState(), 'addAddress.data.addressId');

      if (requestRememberMeCookie) {
        await window.srcsdk.addConsumerIdentifiers({
          srcCorrelationId,
          requestRememberMeCookie,
          appInstance: APP_INSTANCE,
        });
      }

      const { checkoutResponse } = await window.srcsdk.checkout({
        route: 'dcf',
        srciTransactionId,
        srcCorrelationId,
        srcDigitalCardId: shouldFail
          ? 'invalidSrcDigitalCardId'
          : srcDigitalCardId,
        dpaTransactionOptions: {
          DpaData: {
            dpaName: 'testDpa',
          },
          threeDsInputData: {
            acquirerMid: '7332',
            requestorId: '7848',
            acquirerId: '120',
          },
          transactionType: 'PURCHASE',
          threeDsPreference: 'NONE',
          transactionAmount: {
            transactionAmount: 14.99,
            transactionCurrencyCode: '840',
          },
        },
        shippingAddressId,
      });

      const checkoutResponseUri = get(getState(), 'checkoutResponseUri');

      if (checkoutResponseUri) {
        const card = get(getState(), 'card');
        const shippingAddress = get(getState(), 'addAddress.data');
        const idToken = get(getState(), 'idToken', null);

		var checkoutPath = "?action=COMPLETE";

		if (!idToken) {
			checkoutPath = checkoutPath + `#${checkoutResponse}`;
		} else {
			checkoutPath = checkoutPath + "&IDToken=" + `${idToken}#${checkoutResponse}`;
		}			
		
		console.log (checkoutResponseUri + checkoutPath);

        window.location.href = checkoutResponseUri + checkoutPath;

      }

    } catch (error) {
      const errorDescription = get(
        error,
        'response.data.message',
        'Something went wrong'
      );
      const errorCode = get(error, 'response.data.reason', 'Unknown reason');

      const checkoutResponseUri = get(getState(), 'checkoutResponseUri');

      if (checkoutResponseUri) {
		var errorPath = "?action=ERROR&error=SERVICE_ERROR&errorDescription=SomethingWentWrong";
	    console.log (checkoutResponseUri + errorPath);
        window.location.href = checkoutResponseUri + errorPath;
      }

    }
  };

export const initiateOob = () => async (dispatch, getState) => {
  try {
    dispatch(initiateOobRequest());
    const srcCorrelationId = get(getState(), 'srcCorrelationId');
    const srcDigitalCardId = get(getState(), 'card.srcDigitalCardId');
    const { contactChannels } = await window.srcsdk.oobContactChannels({
      srcDigitalCardId,
      srcCorrelationId,
    });
    const emailContactChannel = find(
      contactChannels,
      ({ type }) => type === 'EMAIL'
    );
    await window.srcsdk.oobAuthentication({
      selectedChannelIdentifier: get(emailContactChannel, 'identifier', null),
      srcCorrelationId,
      srcDigitalCardId,
    });
    dispatch(initiateOobSuccess());
  } catch (error) {
    dispatch(
      initiateOobFailure(
        get(error, 'response.data.error.message', 'Something went wrong')
      )
    );
  }
};

export const completeOob =
  ({ otp }) =>
  async (dispatch, getState) => {
    try {
      dispatch(completeOobRequest());
      const srcCorrelationId = get(getState(), 'srcCorrelationId');
      const srcDigitalCardId = get(getState(), 'card.srcDigitalCardId');
      await window.srcsdk.oobAuthenticationValidation({
        otpCode: otp,
        srcCorrelationId,
        srcDigitalCardId,
      });
      dispatch(completeOobSuccess());
    } catch (error) {
      dispatch(
        completeOobFailure(
          get(error, 'response.data.error.message', 'Something went wrong')
        )
      );
    }
  };

export const getIsLoading = (state) => get(state, 'isLoading');

export const getCard = (state) => get(state, 'card', {}) || {};

export const getIsRecognized = (state) => get(state, 'isRecognized', false);

export const getError = (state) => get(state, 'error');

export const getHasMaskedBillingAddress = (state) =>
  !!get(state, 'card.maskedBillingAddress', false);

export const getIsOobRequired = (state) =>
  some(
    get(state, 'card.digitalCardData.pendingEvents', []),
    (name) => name === 'PENDING_ISSUER_IDV'
  ) && !get(state, 'card.isChoosen', false);

export const getSrcProfile = (state) => get(state, 'srcProfile');

export const getShippingAddresses = (state) =>
  get(state, 'srcProfile.profiles[0].maskedShippingAddresses', []);

export const getShippingAddress = (state) => get(state, 'addAddress.data', {});

export const getAddConsumerIdentifiers = (state) =>
  get(state, 'addConsumerIdentifiers', {});

export const getAddAddress = (state) => get(state, 'addAddress', {});

export const getInitiateIdentityValidation = (state) =>
  get(state, 'initiateIdentityValidation', {});

export const getCompleteIdentityValidation = (state) =>
  get(state, 'completeIdentityValidation', {});

export const getConfirmEnrollment = (state) =>
  get(state, 'confirmEnrollment', {});

export const getInitiateOob = (state) => get(state, 'initiateOob', {});

export const getCompleteOob = (state) => get(state, 'completeOob', {});

export const getIsCheckoutWarning = (state) =>
  get(state, 'confirmEnrollment.success', false) &&
  !get(state, 'checkoutResponseUri');

export const getCheckoutResponseUri = (state) =>
  get(state, 'checkoutResponseUri');
