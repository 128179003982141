import { Button, Divider, Heading } from '@chakra-ui/core';
import { isEmpty } from 'lodash-es';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Redirect, useHistory } from 'react-router-dom';

import Address from '../common/components/Address';
import Loader from '../common/components/Loader';
import {
  addAddressSuccess,
  getIsLoading,
  getIsOobRequired,
  getShippingAddresses,
} from '../slice';

const ChooseAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector(getIsLoading);
  const shippingAddresses = useSelector(getShippingAddresses);
  const isOobRequired = useSelector(getIsOobRequired);

  const onChooseAddress = useCallback(
    (address) => {
      dispatch(addAddressSuccess(address));
      history.push(isOobRequired ? '/oob' : '/confirm-enrollment');
    },
    [dispatch, history, isOobRequired]
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty(shippingAddresses)) {
    return <Redirect to="/add-address" />;
  }

  return (
    <>
      <Heading fontSize="md" my={8}>
        Choose shipping address
      </Heading>
      <Divider my={8} />
      {shippingAddresses.map((address) => (
        <Address
          key={address.addressId}
          address={address}
          onClick={() => onChooseAddress(address)}
        />
      ))}
      <Divider my={8} />
      <Button
        size="lg"
        variantColor="purple"
        as={NavLink}
        to="/add-address"
        leftIcon="add"
      >
        Add new
      </Button>
    </>
  );
};

export default ChooseAddress;
