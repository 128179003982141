import { Box, Stack } from '@chakra-ui/core';
import React, { Fragment } from 'react';
import { Field } from 'react-final-form';

import Select from '../../common/components/Select';
import TextField from '../../common/components/TextField';
import {
  composeValidators,
  mustBeValidZipCode,
  required,
} from '../../common/utils/formValidators';

const COUNTRIES = [{ label: 'Japan', value: 'JP' }];

const AddressFields = ({ keyName = 'billingAddress' }) => (
  <Fragment>
    <Field
      name={`${keyName}.name`}
      label="Name"
      component={TextField}
      validate={composeValidators(required)}
    />
    <Field
      name={`${keyName}.line1`}
      label="Address"
      component={TextField}
      validate={composeValidators(required)}
    />
    <Stack isInline spacing={8}>
      <Box flex={1}>
        <Field
          name={`${keyName}.city`}
          label="City"
          component={TextField}
          validate={composeValidators(required)}
        />
      </Box>
      <Box flex={1}>
        <Field
          name={`${keyName}.zip`}
          label="Zip code"
          component={TextField}
          validate={composeValidators(required, mustBeValidZipCode)}
        />
      </Box>
    </Stack>
    <Field
      name={`${keyName}.countryCode`}
      label="Country"
      component={Select}
      options={COUNTRIES}
      validate={composeValidators(required)}
    />
  </Fragment>
);

export default AddressFields;
