import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
} from '@chakra-ui/core';
import { isEmpty } from 'lodash-es';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../common/components/Checkbox';
import {
  confirmEnrollment,
  getCard,
  getConfirmEnrollment,
  getIsCheckoutWarning,
  getIsRecognized,
  getShippingAddress,
} from '../slice';

const ConfirmEnrollment = () => {
  const dispatch = useDispatch();
  const [requestRememberMeCookie, setRequestRememberMeCookie] = useState(false);

  const card = useSelector(getCard);
  const isRecognized = useSelector(getIsRecognized);
  const shippingAddress = useSelector(getShippingAddress);
  const { isLoading, error } = useSelector(getConfirmEnrollment);
  const isCheckoutWarning = useSelector(getIsCheckoutWarning);

  return (
    <Fragment>
      {error && (
        <Box width={['100%', '100%', '100%', '50%']} my={8}>
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        </Box>
      )}
      {isCheckoutWarning && (
        <Box width={['100%', '100%', '100%', '50%']} my={8}>
          <Alert status="warning">
            <AlertIcon />
            Transaction successful initiated on SRC system side, completion of
            the flow lies on SRCI now
          </Alert>
        </Box>
      )}
      {card && (
        <Fragment>
          <Flex alignItems="center" mt={8}>
            <Text w={24} mr={4}>
              Pay with
            </Text>
            <Box mr={4}>
              <Image
                src={
                  process.env.REACT_APP_CARD_PLACEHOLDER ||
                  card.digitalCardData.artUri
                }
                w={24}
              />
            </Box>
            <Box>
              <Text>SRCPI TEST</Text>
              <Text>**** {card.panLastFour}</Text>
            </Box>
          </Flex>
          <Divider my={8} />
        </Fragment>
      )}
      {!isEmpty(shippingAddress) && (
        <Fragment>
          <Flex alignItems="center" mt={8}>
            <Text width={24} mr={4}>
              Ship to
            </Text>
            <Box>
              <Text>{shippingAddress.name}</Text>
              <Text>{shippingAddress.line1}</Text>
              <Text>{shippingAddress.line2}</Text>
              <Text>{shippingAddress.line3}</Text>
              <Text>
                {shippingAddress.zip} {shippingAddress.city}
              </Text>
              <Text></Text>
            </Box>
          </Flex>
          <Divider my={8} />
        </Fragment>
      )}
      {!isRecognized && (
        <Box my={8}>
          <Checkbox
            name="requestRememberMeCookie"
            label="Remember me on this device for faster checkouts"
            value={requestRememberMeCookie}
            onChange={() => setRequestRememberMeCookie(!requestRememberMeCookie)}	      
          />
        </Box>
      )}
      <Button
        type="submit"
        isLoading={isLoading}
        size="lg"
        variantColor="purple"
        onClick={() => dispatch(confirmEnrollment({ requestRememberMeCookie }))}
      >
        Confirm
      </Button>
    </Fragment>
  );
};

export default ConfirmEnrollment;
