import { Alert, AlertIcon, Box, Button, Heading, Link } from '@chakra-ui/core';
import React, { Fragment, useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from '../common/components/Loader';
import MaskedNumberField from '../common/components/MaskedNumberField';
import { required } from '../common/utils/formValidators';
import {
  completeIdentityValidation,
  getCompleteIdentityValidation,
  getInitiateIdentityValidation,
  initiateIdentityValidation,
} from '../slice';

const ConfirmUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    isLoading: initiateIdentityValidationIsLoading,
    error: initiateIdentityValidationError,
  } = useSelector(getInitiateIdentityValidation);
  const {
    isLoading: completeIdentityValidationIsLoading,
    success: completeIdentityValidationSucces,
    error: completeIdentityValidationError,
  } = useSelector(getCompleteIdentityValidation);

  const onSubmit = useCallback(
    (values) => {
      dispatch(completeIdentityValidation(values));
    },
    [dispatch]
  );

  const onInitiateIdentityValidation = useCallback(() => {	
    dispatch(initiateIdentityValidation());
  }, [dispatch]);

  useEffect(() => {
     onInitiateIdentityValidation();
    }, [onInitiateIdentityValidation]);
  useEffect(() => {
    if (completeIdentityValidationSucces) {
      history.push('/choose-address');
    }
  }, [completeIdentityValidationSucces, history]);

  if (initiateIdentityValidationIsLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Heading fontSize="md" my={8}>
        Enter the code sent to your email
      </Heading>
      <Box width={['100%', '100%', '100%', '50%']}>
        {(initiateIdentityValidationError ||
          completeIdentityValidationError) && (
          <Alert status="error" mb={8}>
            <AlertIcon />
            {initiateIdentityValidationError || completeIdentityValidationError}
          </Alert>
        )}
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="otp"
                component={MaskedNumberField}
                customFormat="# # # # # #"
                validate={required}
              />
              <Link color="purple.500" onClick={onInitiateIdentityValidation}>
              Resend OTP
              </Link>
              <Box mt={8}>
         	<Button
                  size="lg"
                  type="submit"
                  variantColor="purple"
                  isLoading={completeIdentityValidationIsLoading}
                >
                  Continue
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
    </Fragment>
  );
};

export default ConfirmUser;
