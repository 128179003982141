import { isNil } from 'lodash-es';
import validator from 'validator';

export const composeValidators = (...validators) => (value, values) =>
  validators.reduce(
    (error, _validator) => error || _validator(value, values),
    undefined
  );

export const required = (value) => (!isNil(value) ? undefined : 'Required');

export const mustBeValidEmail = (value) =>
  validator.isEmail(value) ? undefined : 'Invalid email';

export const mustBeValidZipCode = (value) =>
  validator.isPostalCode(changePostCode(value), 'JP') ? undefined : 'Invalid zip code';

function changePostCode(value) {
		  if (!value.includes('-')) {
			  		      return value.slice(0, 3) + "-" + value.slice(3);
			  		    }
		  return value;
}
