export const APP_INSTANCE = {
  applicationName: 'app1',
  countryCode: 'US',
  deviceData: {
    brand: 'Nokia',
    manufacturer: 'Nokia',
    model: '3310',
    type: 'Mobile Phone',
  },
  userAgent: 'chrome',
};
