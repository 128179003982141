import { Box, Flex, Icon, PseudoBox, Text } from '@chakra-ui/core';
import React from 'react';

const Address = ({ address, onClick }) => (
  <PseudoBox
    as={Flex}
    _hover={{
      bg: '#f2f2f2',
    }}
    alignItems="center"
    style={{ cursor: 'pointer' }}
    py={4}
    onClick={onClick}
  >
    <Box>
      <Text>{address.name}</Text>
      <Text>{address.line1}</Text>
      <Text>{address.line2}</Text>
      <Text>{address.line3}</Text>
      <Text>
        {address.zip} {address.city}
      </Text>
    </Box>
    <Box ml="auto">
      <Icon name="chevron-right" size={8} />
    </Box>
  </PseudoBox>
);
export default Address;
